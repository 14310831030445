import React, { useState } from "react";
import "./services.scss";
import { CgWebsite } from "react-icons/cg";
import { HiOutlineAnnotation } from "react-icons/hi";
import { MdOutlineComputer } from "react-icons/md";
import { GiComputing } from "react-icons/gi";
import Slide from "react-reveal/Slide";

const Services = () => {
  return (
    <div className="sectionFour" id="sectionFour">
      <h1>Services We Offer</h1>
      <Slide left>
        <div className="InnerSectionFour">
          {/* </div> */}
          <div
            style={{
              width: "20%",
              color: "white",
              padding: "2em 3em",
              backgroundColor: "#141a2a",
              borderRadius: "40px",
              margin: "0em 1em",
              height: "335px",
              textAlign: "center",
            }}
          >
            <MdOutlineComputer style={{ height: "50px", width: "50px" }} />
            <h3>Computer Vision</h3>
            <p>
              We offer solutions to complicated computer vision challenges such
              as Image Classification, Object Detection and Tracking, Image
              Segmentation, Image Generation, and other sorts of content
              analysis on visual data, among others.
            </p>
          </div>
          <div
            style={{
              width: "20%",
              color: "white",
              padding: "2em 3em",
              backgroundColor: "#141a2a",
              borderRadius: "40px",
              margin: "0em 1em",
              height: "335px",
              textAlign: "center",
            }}
          >
            <GiComputing style={{ height: "50px", width: "50px" }} />
            <h3>Machine Learning Frameworks</h3>
            <p>
              We develop custom ML Frameworks on top of PyTorch/Tensorflow/etc,
              with support of MLOps, HyperParameter Tuners, Integrated
              Augmentations, Fully Configurable code or support to monitor
              trainings with GUIs. With conversion from training phase to the
              production frameworks which can be easily integrated in the
              production/embedded devices.
            </p>
          </div>
          <div
            style={{
              width: "20%",
              color: "white",
              padding: "2em 3em",
              backgroundColor: "#141a2a",
              borderRadius: "40px",
              margin: "0em 1em",
              height: "335px",
              textAlign: "center",
              // display: "flex",
            }}
          >
            {/* <div>
            <CgWebsite style={{ height: "40px", width: "40px" }} />
          </div> */}
            {/* <div> */}
            <CgWebsite style={{ height: "50px", width: "50px" }} />

            <h3>Full Stack Applications</h3>
            <p>
              We use the most up-to-date tech stacks to create custom front-end
              and back-end solutions for your project, all while maintaining
              code quality and security.
            </p>
          </div>
          <div
            style={{
              width: "20%",
              color: "white",
              padding: "2em 3em",
              backgroundColor: "#141a2a",
              borderRadius: "40px",
              margin: "0em 1em",
              height: "335px",
              textAlign: "center",
            }}
          >
            <HiOutlineAnnotation style={{ height: "50px", width: "50px" }} />
            <h3>Data Collection & Annotation</h3>
            <p>
              Our experience team of data collectors and annotators which come
              from the same background, will collect the correct and right
              amount of data required for your machine learning applications.
              And annotate the data with best practices and quality layers
              learned from years of experience.
            </p>
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default Services;

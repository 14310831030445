import React from "react";
import "./App.css";
import Home from "./components/home-page/home";
import ContactUs from "./components/contact-us/contactUs";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import { BrowserRouter, Switch, Route } from "react-router-dom";

function App() {
  return (
    <div className="app-container">
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/contact-us" component={ContactUs} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from "react";
import "./header.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Logo from "../../assets/final.png";

import { ImCross } from "react-icons/im";
import { GiHamburgerMenu } from "react-icons/gi";

const Header = () => {
  const history = useHistory();
  const location = useLocation();

  //widthOfWindow
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  //state of sidebar open/closed
  const [sidebar, setSidebar] = useState(false);
  const mobileWindowMaxSize = 450; //max size for mobile phones

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const navigate = (target) => {
    //closes sidebar on when navigated to an option
    if (sidebar) setSidebar(false);
    switch (target) {
      case "home":
        history.push("/");
        break;
      case "contact-us":
        history.push("/contact-us");
        break;
      default:
        break;
    }
  };

  const navbar = document.querySelector(".header");
  if (navbar)
    window.onscroll = () => {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 200
      ) {
        navbar.classList.add("nav-active");
      } else {
        navbar.classList.remove("nav-active");
      }
    };
  return (
    <div className="header" role="banner" id="header">
      <div className="header-container">
        {sidebar ? (
          <div className="side-bar">
            <div className="close-sidebar">
              <button href="">
                <ImCross onClick={() => toggleSidebar()} />
              </button>
            </div>
            <ul className="homepage-nav-menu">
              <li onClick={() => navigate("home")}>
                <button
                  href=""
                  className={`${location.pathname === "/" ? "current" : ""}`}
                >
                  Home
                </button>
              </li>
              <li>
                <a href="#sectionFour">Services</a>
              </li>
              <li>
                  <button>
                    <a href="#SectionFive">Our Vision</a>
                  </button>
                </li>
              <li onClick={() => navigate("home")}>
                <button
                  href=""
                  className={`${location.pathname === "//" ? "current" : ""}`}
                >
                  <a href="">Contact Us</a>
                </button>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}

        <div className="homepage-navbar">
          <div className="homepage-nav-img" onClick={() => navigate("home")}>
            <img
              src={Logo}
              alt="img not available"
              className={`${location.pathname === "/" ? "current" : ""}`}
            />
          </div>
          <div className="homepage-nav-group">
            {windowSize <= mobileWindowMaxSize ? (
              <button href="" className="menu" onClick={() => toggleSidebar()}>
                <GiHamburgerMenu />
              </button>
            ) : (
              <ul className="homepage-nav-group-list">
               
                <li>
                  <button>
                    <a href="#sectionFour">Services</a>
                  </button>
                </li>
                <li>
                  <button>
                    <a href="#SectionFive">Our Vision</a>
                  </button>
                </li>
                <li>
                  <button>
                    <a href="mailto:bilal@wortel.ai">Contact Us</a>
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

import React from "react";
import "./home.scss";
import { useHistory } from "react-router-dom";
import Tech from "../../assets/Tech.png";
import Proofminder from "../../assets/proofminder.png";
import Netra from "../../assets/unnamed.png";
import Spartans from "../../assets/spartans.png";
import Testimonials from "../testimonial/testimonial";
import Pipeline from "../PipeLine/pipeline";
import Services from "../Services/services";
import Vision from "../Vision/vision";
import SimpleSlider from "../testimonial/testimonial";
import Rotate from "react-reveal/Rotate";
import Flip from "react-reveal/Flip";

const Home = () => {
  const history = useHistory();

  return (
    <main id="Home">
      {/* Panel no 1 */}
      <div className="sectionOne" id="sectionOne">
        <div className="sectionOneLeft">
          <h1>
            We <strong className="strong">Design </strong> &{" "}
            <strong className="strong">
              <br />
              Build
            </strong>
            Models
          </h1>
          <h3>
            We provide services to solve complex computer vision problems like
            Image Classification, Object Detection and Tracking, Image
            Segmentation, Image generation & various types of analysis on the
            content of visual data and so on.
          </h3>
          {/* <button onClick={() => history.push("/About-us")}>
            GET STARTED{" "}
          </button> */}
        </div>
        <div className="sectionOneRight">
          <img src={Tech} />
        </div>
      </div>

      <div className="Services">
        
          <Services />
      </div>
      <div className="sectionTwo">
        <h1>Our Clients</h1>
        <div>
          <Flip top>
            <a
              href="https://proofminder.com"
              target="_blank"
            >
              <img src={Proofminder} alt="" height="35px" />
            </a>
            <a
              href="https://proofminder.com"
              target="_blank"
            >
              <img src={Netra} alt="" height="35px" />
            </a>
            <a
              href="https://proofminder.com"
              target="_blank"
            >
              <img src={Spartans} alt="" height="40px" />
            </a>
           
          </Flip>
        </div>
      </div>
      <div
        style={{
          background:
            "linear-gradient(to right, #fff 0%, #fff 40%, #141a2a 40%, #141a2a 100%)",
          margin: "2em 0em",
          padding: "2em",
          textAlign: "center",
        }}
      >
        <Rotate top left>
          <SimpleSlider />
        </Rotate>
        {/* <ImageSlider slides={SliderData} /> */}
      </div>

      <Vision />
    </main>
  );
};

export default Home;

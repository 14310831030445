import React from "react";
import Slider from "react-slick";
import "./testimonial.scss";
export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };
  const myFunction = () => {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  };
  return (
    <Slider {...settings}>
      <div className="Block">
        <p>
          "Bilal and the team are always a pleasure to work with. They are a
          team of experienced Machine Learning Engineers and Web Developers. who
          deliver quality work. They always go the extra mile to use best
          practices and deliver a quality product. I have always loved the
          transparency and professionalism they employ at work"
        </p>

        <h3>
          Shashi Kant -{" "}
          <a href="https://www.netra.io" target="_blank">
          CEO Netra
          </a>
        </h3>
      </div>
      <div className="Block">
        <p>
          "As an agriculture startup we've been looking for a Computer Vision
          expert, someone with real word tested and proven skills, not only
          theoretical knowledge. We've found this and much more in the many
          months of collaboration with Bilal - he always strived to understand
          and solve our real problems instead of just asking us what to do."
        </p>

        <h3>
          Norbert H. | <a href="https://proofminder.com" target="_blank"> CTO Proofminder</a>
        </h3>
      </div>
      <div className="Block">
        <p>
          "I worked closely with Bilal for almost 6 months. He is a talented ML
          engineer and is pretty hardworking as well. Bilal is detail oriented,
          passionate and a good team player."
        </p>
        <h3>Azam I. | Ex. Lead Data Scientist at Microsoft</h3>
      </div>
    </Slider>
  );
}

import React from "react";
import "./footer.scss";
import { ImLinkedin } from "react-icons/im";
import { FaTwitter } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { BsFillArrowUpCircleFill } from "react-icons/bs";

import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";

const Footer = () => {

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {

    if (
      document.body.scrollTop > 500 ||
      document.documentElement.scrollTop > 500
    ) {
      document.getElementById("Top").style.display = "block";
    } else {
      document.getElementById("Top").style.display = "none";
    }
  };
  const topFunction = () => {
    console.log("TOP");
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="columnOne">
          <Fade top>
            <div className="Heading">
              <h1>WORTEL AI</h1>{" "}
            </div>
          </Fade>
          <div>
            <Bounce bottom>
              <div style={{ display: "flex", alignItems: "center" }}>
                <section>
                  <a className="Image_div" href="#" target="_blank">
                    <FaTwitter style={{ height: "30px", width: "30px" }} />
                  </a>
                  <a className="Image_div" href="#" target="_blank">
                    <SiGmail style={{ height: "30px", width: "30px" }} />
                  </a>
                  <a
                    className="Image_div"
                    href="#"
                    target="_blank"
                    style={{ margin: "0px" }}
                  >
                    <ImLinkedin style={{ height: "30px", width: "30px" }} />
                  </a>
                </section>
              </div>
              <h3>info@wortelai.com</h3>
            </Bounce>
          </div>
          <h4 onClick={() => topFunction()} id="Top" title="Go to top">
            <BsFillArrowUpCircleFill
              style={{ width: "30px", height: "30px" }}
            />
          </h4>
        </div>{" "}
      </div>
    </footer>
  );
};

export default Footer;

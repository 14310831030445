import React from "react";
import "./vision.scss";
import Slide from "react-reveal/Slide";
import Flip from "react-reveal/Flip";
// import twitter from "../../Images/twitter.svg";
// import Instagram from "../../Images/Instagram.svg";
import VisionImage from "../../assets/Vision1.png";
import { ImLinkedin } from "react-icons/im";
import { FaTwitter } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import Bounce from "react-reveal/Bounce";

const Vision = () => {
  return (
    <div className="SectionFive" id="SectionFive">
      <div className="InnerSectionTwo">
        <Flip left>
          <img
            src={VisionImage}
            style={{
              width: "15em",
              borderRadius: "100px",
            }}
            alt="Error"
          />
        </Flip>
      </div>
      <Slide right>
        <div className="InnerSectionOne">
          <h1>Our Vision</h1>
          <p>
            We are working towards being an end-to-end provider for your machine
            learning solutions. We will own the entire workflow, we will collect
            data, train and maintain your machine learning models while you can
            serve the clients with the insights/value we derive from the data.
          </p>
          <br />
         
        </div>
      </Slide>
    </div>
  );
};

export default Vision;
